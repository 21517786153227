import { getGql, schToMut } from "../_"

type Variables = Partial<Record<string, any>>

export default (a: NuxtAxiosInstance) => {
  const gql = getGql(a)

  return {
    getMyCongratulation: () => gql(
      `
        query {
          listMyCongratulation {
            items {
              id
            }
          }
        }
      `,
    ),

    getBalls: (variables: Variables) => gql(
      `
        query result (
          $limit: Int! = 25
        ) {
          listBall (limit: $limit) {
            items {
              id
              image { url }
              name
              congratulationCount
              light
            }
          }
        }
      `, variables,
    ),

    createCongratulation: (variables: Variables) => gql(schToMut(
      `
        createCongratulation(
          text: String
          picture: UploadPath
          agreement: Boolean!
        ): Congratulation!
      `,
    ), variables),
  }
}
