// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._9-rBUwaj{padding:var(--spacing-xxl) var(--spacing-md);z-index:1}@media(max-width:1023.98px){._9-rBUwaj{padding:var(--spacing-lg) var(--spacing-sm)}}@media(max-width:667.98px){._9-rBUwaj{padding:var(--spacing-md) var(--spacing-xs)}}.Z6NpOHlC{display:flex;gap:.7rem;height:100%;justify-content:space-between;margin:0 auto;max-width:var(--content-width)}@media(max-width:667.98px){.Z6NpOHlC{align-items:center;flex-direction:column;gap:var(--spacing-md)}}.K-N35Rcl{align-items:center;display:flex;flex-direction:column;position:relative}.pPV0mo90{color:var(--color-blue);font-family:\"Pragmatica\",\"Arial\",sans-serif;font-size:max(1.5rem,min(2.08vw,2.5rem));font-weight:900;line-height:1;text-align:center;text-shadow:0 0 7px var(--color-gray-1);text-transform:uppercase;white-space:nowrap}@media(max-width:667.98px){.pPV0mo90{white-space:normal}}.kSZx20Tx{background-color:var(--color-red);color:var(--color-bg);font-size:max(.5rem,min(2vw,1.13rem));font-weight:600;left:50%;padding:.1rem .5rem;position:absolute;top:2.5rem;transform:translateX(-50%) rotate(-2deg);white-space:nowrap}.kSZx20Tx:after{background:url(/landing/balls.png) 50%/100% auto no-repeat;content:\"\";height:5rem;left:0;position:absolute;top:-10%;transform:rotate(3deg);width:100%}@media(max-width:1023.98px){.kSZx20Tx{top:4vw}}@media(max-width:667.98px){.kSZx20Tx{position:static;transform:translateX(0) rotate(-3deg)}.kSZx20Tx:after{display:none}}.kUbRoajb{--icon-size:3em;color:var(--color-blue)!important}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xs": "667.98",
	"sm": "947.98",
	"md": "1023.98",
	"lg": "1229.98",
	"container": "_9-rBUwaj",
	"content": "Z6NpOHlC",
	"title": "K-N35Rcl",
	"titleMain": "pPV0mo90",
	"titleOver": "kSZx20Tx",
	"logout": "kUbRoajb"
};
module.exports = ___CSS_LOADER_EXPORT___;
