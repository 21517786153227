
import Vue from "vue"
import type Snowflakes from "magic-snowflakes"

export default Vue.extend({
  props: {
    settings: { type: Object, default: () => ({}) },
  },

  data: () => ({
    snowflakes: null as null | Snowflakes,
  }),

  async mounted () {
    const wrapper = this.$refs.wrapper as HTMLElement
    if (wrapper)
      try {
        const Snowflakes = (await import("magic-snowflakes")).default
        this.snowflakes = new Snowflakes({
          color: "#a8baf4",
          wind: false,
          zIndex: -2,
          container: wrapper,
        })
        this.snowflakes.start()
      } catch (e) {
        console.error(e)
      }
  },

  beforeDestroy () {
    this.snowflakes?.destroy()
  },
})
