import { render, staticRenderFns } from "./AppHeaderLogo.vue?vue&type=template&id=17804599&"
import script from "./AppHeaderLogo.vue?vue&type=script&lang=js&"
export * from "./AppHeaderLogo.vue?vue&type=script&lang=js&"
import style0 from "./AppHeaderLogo.vue?vue&type=style&index=0&id=17804599&prod&module=true&lang=scss&"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppLogo: require('/app/components/app/AppLogo.vue').default})
