
import Vue from "vue"
import type { AnimationItem } from "lottie-web"

export default Vue.extend({
  data: () => ({
    catAnim: null as AnimationItem | null,
  }),

  async mounted () {
    const catEl = this.$refs.cat as HTMLElement
    if (catEl)
      try {
        const lottie = (await import("lottie-web")).default
        this.catAnim = lottie.loadAnimation({
          container: catEl,
          // renderer: 'svg',
          // loop: true,
          // autoplay: true,
          path: "/landing/cat-lottie.json",
        })
      } catch {}
  },

  beforeDestroy () {
    this.catAnim?.destroy()
  },
})
