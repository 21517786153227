
import Vue from "vue"
import { mapState } from "vuex"

export default Vue.extend({
  computed: {
    ...mapState("app", ["isNewYear"]),
  },

  methods: {
    async logout () {
      await this.$auth.logout()

      this.$notify({
        type: "success",
        title: "Выход",
        message: "Вы вышли из аккаунта",
      })
    },
  },
})
