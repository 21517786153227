export const actions = {
  async nuxtServerInit ({ commit, dispatch }) {
    const newYearDate = Date.UTC(2023, 11, 31, 9) // 2023-12-31T12:00+03:00
    const newYearViaRoute =
      this.app.router.currentRoute.fullPath === "/?newyear=1"
    commit("app/SET_IS_NEW_YEAR",
      Date.now() >= newYearDate || newYearViaRoute)

    await Promise.all([
      dispatch("app/FETCH_EVENT"),
      // dispatch("app/FETCH_MENU"),
      // dispatch("live/FETCH_HALLS"),
    ])
  },
}
