// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app-spinner{align-self:center;box-sizing:border-box;display:block;height:3rem;margin:0 auto;position:relative;width:3rem}.app-spinner:after,.app-spinner:before{border-radius:50%;content:\"\";height:3rem;left:0;position:absolute;width:3rem}.app-spinner:before{animation:spinner .65s linear infinite;border:2px solid transparent;border-top-color:currentcolor}.app-spinner:after{border:2px solid;opacity:.25}@keyframes spinner{0%{transform:rotate(0deg)}to{transform:rotate(359deg)}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xs": "667.98",
	"sm": "947.98",
	"md": "1023.98",
	"lg": "1229.98"
};
module.exports = ___CSS_LOADER_EXPORT___;
