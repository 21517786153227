import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _49bc1ec3 = () => interopDefault(import('../pages/nominant/index.vue' /* webpackChunkName: "pages/nominant/index" */))
const _2dfb1658 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _29f8dfcd = () => interopDefault(import('../pages/user/index.ts' /* webpackChunkName: "pages/user/index" */))
const _1c75ff9a = () => interopDefault(import('../pages/auth/impersonalize.vue' /* webpackChunkName: "pages/auth/impersonalize" */))
const _8e1272a8 = () => interopDefault(import('../pages/auth/login.vue' /* webpackChunkName: "pages/auth/login" */))
const _23d2ff46 = () => interopDefault(import('../pages/auth/registration.vue' /* webpackChunkName: "pages/auth/registration" */))
const _31cd20b0 = () => interopDefault(import('../pages/user/congratulation.vue' /* webpackChunkName: "pages/user/congratulation" */))
const _1c821674 = () => interopDefault(import('../pages/user/nominants/index.vue' /* webpackChunkName: "pages/user/nominants/index" */))
const _965279dc = () => interopDefault(import('../pages/user/profile/index.vue' /* webpackChunkName: "pages/user/profile/index" */))
const _808155b2 = () => interopDefault(import('../pages/user/team.vue' /* webpackChunkName: "pages/user/team" */))
const _3bd6f0dc = () => interopDefault(import('../pages/user/teams.vue' /* webpackChunkName: "pages/user/teams" */))
const _2a6fc7db = () => interopDefault(import('../pages/auth/oauth/_provider.vue' /* webpackChunkName: "pages/auth/oauth/_provider" */))
const _00211ea4 = () => interopDefault(import('../pages/user/nominants/_id.vue' /* webpackChunkName: "pages/user/nominants/_id" */))
const _2efaf336 = () => interopDefault(import('../pages/_.vue' /* webpackChunkName: "pages/_" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/nominant",
    component: _49bc1ec3,
    name: "nominant___ru___default"
  }, {
    path: "/ru",
    component: _2dfb1658,
    name: "index___ru"
  }, {
    path: "/user",
    component: _29f8dfcd,
    name: "user___ru___default"
  }, {
    path: "/auth/impersonalize",
    component: _1c75ff9a,
    name: "auth-impersonalize___ru___default"
  }, {
    path: "/auth/login",
    component: _8e1272a8,
    name: "auth-login___ru___default"
  }, {
    path: "/auth/registration",
    component: _23d2ff46,
    name: "auth-registration___ru___default"
  }, {
    path: "/ru/nominant",
    component: _49bc1ec3,
    name: "nominant___ru"
  }, {
    path: "/ru/user",
    component: _29f8dfcd,
    name: "user___ru"
  }, {
    path: "/user/congratulation",
    component: _31cd20b0,
    name: "user-congratulation___ru___default"
  }, {
    path: "/user/nominants",
    component: _1c821674,
    name: "user-nominants___ru___default"
  }, {
    path: "/user/profile",
    component: _965279dc,
    name: "user-profile___ru___default"
  }, {
    path: "/user/team",
    component: _808155b2,
    name: "user-team___ru___default"
  }, {
    path: "/user/teams",
    component: _3bd6f0dc,
    name: "user-teams___ru___default"
  }, {
    path: "/ru/auth/impersonalize",
    component: _1c75ff9a,
    name: "auth-impersonalize___ru"
  }, {
    path: "/ru/auth/login",
    component: _8e1272a8,
    name: "auth-login___ru"
  }, {
    path: "/ru/auth/registration",
    component: _23d2ff46,
    name: "auth-registration___ru"
  }, {
    path: "/ru/user/congratulation",
    component: _31cd20b0,
    name: "user-congratulation___ru"
  }, {
    path: "/ru/user/nominants",
    component: _1c821674,
    name: "user-nominants___ru"
  }, {
    path: "/ru/user/profile",
    component: _965279dc,
    name: "user-profile___ru"
  }, {
    path: "/ru/user/team",
    component: _808155b2,
    name: "user-team___ru"
  }, {
    path: "/ru/user/teams",
    component: _3bd6f0dc,
    name: "user-teams___ru"
  }, {
    path: "/ru/auth/oauth/:provider?",
    component: _2a6fc7db,
    name: "auth-oauth-provider___ru"
  }, {
    path: "/ru/user/nominants/:id",
    component: _00211ea4,
    name: "user-nominants-id___ru"
  }, {
    path: "/auth/oauth/:provider?",
    component: _2a6fc7db,
    name: "auth-oauth-provider___ru___default"
  }, {
    path: "/user/nominants/:id",
    component: _00211ea4,
    name: "user-nominants-id___ru___default"
  }, {
    path: "/ru/*",
    component: _2efaf336,
    name: "all___ru"
  }, {
    path: "/",
    component: _2dfb1658,
    name: "index___ru___default"
  }, {
    path: "/*",
    component: _2efaf336,
    name: "all___ru___default"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
