import { render, staticRenderFns } from "./AppBtn.vue?vue&type=template&id=5a7a953a&"
import script from "./AppBtn.vue?vue&type=script&lang=js&"
export * from "./AppBtn.vue?vue&type=script&lang=js&"
import style0 from "./AppBtn.vue?vue&type=style&index=0&id=5a7a953a&prod&module=true&lang=scss&"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppSpinner: require('/app/components/app/AppSpinner.vue').default,AppIcon: require('/app/components/app/AppIcon.vue').default})
