// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._4x3rzjpL{padding:max(1.76rem,min(2.6vw,3.13rem)) 0}._4x3rzjpL .B81NySmf{justify-content:center}.Jvc56TKF{-moz-column-gap:max(1.76rem,min(2.6vw,3.13rem));column-gap:max(1.76rem,min(2.6vw,3.13rem));row-gap:max(1.41rem,min(2.08vw,2.5rem))}.TQo0Jd5p,.Jvc56TKF,.tUd3ohrI{align-items:center;display:flex;flex-wrap:wrap;justify-content:center}.TQo0Jd5p,.tUd3ohrI{-moz-column-gap:max(1.05rem,min(1.56vw,1.88rem));column-gap:max(1.05rem,min(1.56vw,1.88rem));font-size:.75rem;font-weight:500;line-height:normal;row-gap:max(.81rem,min(1.2vw,1.44rem));text-align:center}@media(hover:hover){.TQo0Jd5p a:hover,.tUd3ohrI a:hover{-webkit-text-decoration:underline;text-decoration:underline}}@media(hover:none){.TQo0Jd5p a:active,.tUd3ohrI a:active{-webkit-text-decoration:underline;text-decoration:underline}}.TQo0Jd5p{align-items:center;-moz-column-gap:max(2.11rem,min(3.13vw,3.75rem));column-gap:max(2.11rem,min(3.13vw,3.75rem));display:flex;flex-wrap:wrap;font-size:1rem;justify-content:center;position:relative;row-gap:max(.81rem,min(1.2vw,1.44rem))}@media(max-width:667.98px){.TQo0Jd5p{align-items:center;flex-direction:column}}.TQo0Jd5p>*{align-items:center;display:flex;flex-direction:column;position:relative}.n5KHFGXq{position:absolute;right:max(-32rem,min(-26.67vw,-4rem));top:50%;transform:translateY(-50%) rotateY(180deg);width:max(9rem,min(13.33vw,16rem))}@media(max-width:667.98px){.n5KHFGXq{right:max(-5rem,min(-4.17vw,-2.81rem));top:20%;width:max(4.5rem,min(6.67vw,8rem))}}.tjojuc-S{font-size:.8em;font-weight:400;margin-bottom:.4em;max-width:30ch;opacity:.6}.HF36kVNH>*+*{margin-top:.5em}.m0fdaasP{font-size:.6em;opacity:.8}.hOlXEEo3{display:flex;justify-content:center;margin-top:max(1.41rem,min(2.08vw,2.5rem))}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xs": "667.98",
	"sm": "947.98",
	"md": "1023.98",
	"lg": "1229.98",
	"container": "_4x3rzjpL",
	"logos": "B81NySmf",
	"content": "Jvc56TKF",
	"contacts": "TQo0Jd5p",
	"links": "tUd3ohrI",
	"cat": "n5KHFGXq",
	"contactTitle": "tjojuc-S",
	"contactInner": "HF36kVNH",
	"contactSub": "m0fdaasP",
	"socialsWrapper": "hOlXEEo3"
};
module.exports = ___CSS_LOADER_EXPORT___;
